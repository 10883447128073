import React, { useEffect, useState, useContext } from 'react';
import { Input, CircularProgress } from '@material-ui/core';
import { SecondaryButton } from '../../../Core/FormInput';
import { getBranchLimits, postBranchLimitData } from '../../../Core/Service/branch-screen-service';
import AppContext from '../../../App/AppContext';

const BranchLimits = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        id: null,
        branchCode: '',
        branchName: '',
        showLoader: true
    });
    useEffect(() => {
        const fetchData = async () => {
            if (props.branchID) {
                try {
                    let res = await getBranchLimits(props.branchID);
                    if (res.success) {
                        setState((st) => ({
                            ...st,
                            branchName: props.branchName || '',
                            branchCode: props.branchCode || '',
                            id: props.branchID,
                            demoLimit: res.data.details[0].demoStockLimit,
                            courtesyLimit: res.data.details[0].courtesyCarLimit,
                            overallLimit: res.data.details[0].overallLimit,
                            cMVLimit: res.data.details[0].cMVLimit,
                            showLoader: false
                        }));
                    } else {
                        // Handle the case where the request was not successful
                        console.error('Request was not successful:');
                    }
                } catch (error) {
                    // Handle any other errors that might occur during the asynchronous operation
                    console.error('An error occurred:', error);
                }
            }
        };

        fetchData();
    }, []);

    const submitHandler = async () => {
        const branchLimitData = {
            id: state.id,
            demoLimit: state.demoLimit,
            courtesyLimit: state.courtesyLimit,
            overallLimit: state.overallLimit,
            cMVLimit: state.cMVLimit
        };

        let res = await postBranchLimitData(branchLimitData);
        if (res.success) {
            showToast('Branch Updated sucessfully');
            props.onClose(true);
        } else {
            showToast(res.message);
        }
    };

    const handleInputChange = (input, event) => {
        if (input === "courtesy") {
            setState((st) => ({
                ...st,
                courtesyLimit: event.target.value,
            }));
        }

        if (input === "overall") {
            setState((st) => ({
                ...st,
                overallLimit: event.target.value,
            }));
        }

        if (input === "demo") {
            setState((st) => ({
                ...st,
                demoLimit: event.target.value,
            }));
        }

        if (input === "cmv") {
            setState((st) => ({
                ...st,
                cMVLimit: event.target.value,
            }));
        }
    }
    
    return (
        <>
            <div className='branchLimitsWrp'>
                {state.showLoader ? (
                    <CircularProgress />
                ) : (
                    <>
                        <div className='branchLimitsInr'>
                            <div className='branchLimitsLine'>
                                <span>Overall Limit</span>
                                <Input className='customInput' type='number' value={state.overallLimit} onChange={(e) => handleInputChange("overall", e)} />
                            </div>
                            <div className='branchLimitsLine'>
                                <span>Demo Limit</span>
                                <Input className='customInput' type='number' value={state.demoLimit} onChange={(e) => handleInputChange("demo", e)} />
                            </div>
                            <div className='branchLimitsLine'>
                                <span>Courtesy Car Limit</span>
                                <Input className='customInput' type='number' value={state.courtesyLimit} onChange={(e) => handleInputChange("courtesy", e)} />
                            </div>
                            <div className='branchLimitsLine'>
                                <span>CMV Limit</span>
                                <Input className='customInput' type='number' value={state.cMVLimit} onChange={(e) => handleInputChange("cmv", e)} />
                            </div>
                        </div>
                        <SecondaryButton onClick={submitHandler}>
                            Save
                        </SecondaryButton>
                    </>
                    )
                }
            </div>
           
        </>
    );
}

export default BranchLimits;